type UriPath = `/${string}`

/**
 * Handles all requests to API
 * @param uri
 * @param config
 * @returns {Promise<T>}
 */
async function handleAPIRequest<T = undefined>(uri: UriPath, config: RequestInit): Promise<T> {
  const request = await fetch(`/api${uri}`, config)
  // Check if the request response is JSON
  const contentType = request.headers.get('content-type')

  if (!contentType || !contentType.includes('application/json')) {
    return request.text() as T
  }

  return await request.json()
}

/**
 * Handles all GET requests
 * @param uri
 * @returns {Promise<T>}
 */
export async function handleAPIGet<T>(uri: UriPath): Promise<T> {
  return await handleAPIRequest(uri, { method: 'GET' })
}

/**
 * Handles all POST requests
 * @param uri
 * @param body
 * @returns {Promise<T>}
 */
export async function handleAPIPost<T, Body>(uri: UriPath, body: Body): Promise<T> {
  return await handleAPIRequest<T>(uri, {
    method: 'POST',
    body: body ? JSON.stringify(body) : undefined,
  })
}

/**
 * Handles all PUT requests
 * @param uri
 * @param body
 * @returns {Promise<T>}
 */
export async function handleAPIPut<T, Body>(uri: UriPath, body: Body): Promise<T> {
  return handleAPIRequest<T>(uri, { method: 'PUT', body: body ? JSON.stringify(body) : undefined })
}

/**
 * Handles all PATCH requests
 * @param uri
 * @param body
 * @returns {Promise<T>}
 */
export async function handleAPIPatch<T, Body>(uri: UriPath, body: Body): Promise<T> {
  return handleAPIRequest<T>(uri, {
    method: 'PATCH',
    body: body ? JSON.stringify(body) : undefined,
  })
}

/**
 * Handles all DELETE requests
 * @param uri
 * @returns {Promise<T>}
 */
export async function handleAPIDelete<T>(uri: UriPath): Promise<T> {
  return handleAPIRequest<T>(uri, { method: 'DELETE' })
}
