import { Outlet, createBrowserRouter } from 'react-router-dom'

import CanceledLogin from './CanceledLogin/CanceledLogin'
import Error from './Error'
import Login from './Login'
import Authenticate from './Login/Authenticate'
import Organization from './Login/Organization'
import CreateOrganization from './Login/Organization/CreateOrganization'
import SelectOrganization from './Login/Organization/SelectOrganization'
import Logout from './Logout/Logout'
// eslint-disable-next-line etc/no-commented-out-code
//import NewsLetters from './Portal/NewsLetters'
import Notifications from './Portal/Notifications'
// eslint-disable-next-line etc/no-commented-out-code
//import OrganizationDetails from './Portal/OrganizationDetails'
import OrganizationRequests from './Portal/Organizations'
import UserProfile from './Portal/UserProfile'
import Profile from './Profile'
import Register from './Register'
import AccountCreation from './Register/AccountCreation'
import StrongIdentification from './Register/StrongIdentification'
import WeakIdentication from './Register/WeakIdentication/WeakIdentication.component'
import Sso from './Sso'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Authenticate />,
      },
      {
        path: 'organization',
        element: <Organization />,
        children: [
          {
            path: '/organization/',
            element: <SelectOrganization />,
          },
          {
            path: 'new',
            element: <CreateOrganization />,
          },
        ],
      },
    ],
  },
  {
    path: 'register',
    element: <Register />,
    children: [
      {
        path: '/register',
        element: <StrongIdentification />,
      },
      {
        path: '/register/create-account',
        element: <AccountCreation />,
      },
      {
        path: '/register/weak-identication',
        element: <WeakIdentication />,
      },
    ],
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'canceledlogin',
    element: <CanceledLogin />,
  },
  {
    path: 'portal',
    element: <Outlet />,
    children: [
      {
        path: '/portal/',
        element: <UserProfile />,
      },
      {
        path: 'organization',
        element: <OrganizationRequests />,
      },
      // eslint-disable-next-line etc/no-commented-out-code
      /*
      {
        path: 'organization/:organizationId',
        element: <OrganizationDetails />,
      },
      */
      {
        path: 'notifications',
        element: <Notifications />,
      },
      // eslint-disable-next-line etc/no-commented-out-code
      /*
      {
        path: 'newsletters',
        element: <NewsLetters />,
      },
      */
    ],
  },
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'sso',
    element: <Sso />,
  },
])

export default router
