import { SessionStorageKeys, WeakIdenticationModes } from './WeakIdentication.schema'

/**
 * Sets the redirect url for the weak identication.
 * @param mode
 */
export function setWeakIdenticationRedirectUrl(mode: WeakIdenticationModes) {
  const redirectUrl = `/weak-identication?view=miscInformation&mode=${mode}`
  sessionStorage.setItem(SessionStorageKeys.redirectUrl, redirectUrl)
}

/**
 * Sets e-mail for the weak identication.
 * @param email
 */
export function setWeakIdenticationEmail(email: string) {
  sessionStorage.setItem(SessionStorageKeys.email, email)
}

/**
 * Gets the redirect url for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdenticationRedirectUrl(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.redirectUrl)
}

/**
 * Gets the e-mail for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdenticationEmail(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.email)
}
