import { handleAPIGet, handleAPIPost } from '../../../utils/network-utils'
import {
  WeakIdenticationEmailStepType,
  WeakIdenticationJoinOrganizationStepType,
  WeakIdenticationModes,
  WeakIdenticationValidationMiscInformationStepType,
  WeakIdenticationValidationVerificationCodeStepType,
} from './WeakIdentication.schema'

/**
 * Store query- and mutationkeys in the same place as requests
 */
export enum WeakIdenticationRequestKeys {
  authenticationStep = 'WEAK_IDENTICATION_AUTHENTICATION_STEP',
  emailStep = 'WEAK_IDENTICATION_EMAIL_STEP',
  authenticationCode = 'WEAK_IDENTICATION_AUTHENTICATION_CODE',
  miscInformation = 'WEAK_IDENTICATION_MISC_INFORMATION_STEP',
  checkOrganization = 'WEAK_IDENTICATION_CHECK_ORGANIZATION',
  joinToOrganization = 'WEAK_IDENTICATION_JOIN_TO_ORGANIZATION',
}

// #region starts for the requests

/**
 * Fetches the authorization link for the weak identication
 * @param type
 * @returns {Promise<string>}
 */
export const handleWeakIdenticationAuthorizationLink = async (
  type: WeakIdenticationModes
): Promise<string> => {
  return await handleAPIGet<string>(`/weak-identication?type=${type}`)
}

export const handleWeakIdenticationEmail = async (
  body: WeakIdenticationEmailStepType
): Promise<unknown> => {
  return await handleAPIPost<unknown, WeakIdenticationEmailStepType>('/', body)
}

export const handleWeakIdenticationVerificationCode = async (
  body: WeakIdenticationValidationVerificationCodeStepType
): Promise<unknown> => {
  return await handleAPIPost<unknown, WeakIdenticationValidationVerificationCodeStepType>('/', body)
}

/**
 * Saves misc information about the user
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdenticationMiscInformation = async (
  body: WeakIdenticationValidationMiscInformationStepType
): Promise<unknown> => {
  return await handleAPIPost<unknown, WeakIdenticationValidationMiscInformationStepType>(
    '/weak-identication/misc-information',
    body
  )
}

/**
 * Checks if organizations business id is valid
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdenticationCheckOrganization = async (
  body: WeakIdenticationJoinOrganizationStepType
): Promise<unknown> => {
  return await handleAPIPost<unknown, WeakIdenticationJoinOrganizationStepType>(
    '/weak-identication/check-organization',
    body
  )
}

/**
 * Joins to an organization
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdenticationJoinOrganization = async (
  body: WeakIdenticationJoinOrganizationStepType
): Promise<unknown> => {
  return await handleAPIPost<unknown, WeakIdenticationJoinOrganizationStepType>(
    '/weak-identication/join-to-organization',
    body
  )
}
// #endregion for the requests
