import { useMutation } from '@tanstack/react-query'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Checkbox from '../../../../../components/Checkbox'
import Heading from '../../../../../components/Heading'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import Spacer from '../../../../../components/Spacer'
import FormContainer from '../../../../../containers/FormContainer'
import { withZodValidation } from '../../../../../containers/FormContainer/FormContainer.utils'
import { ColorEnum, Spacing } from '../../../../../shared/enums'
import {
  WeakIdenticationRequestKeys,
  handleWeakIdenticationMiscInformation,
} from '../../WeakIdentication.requests'
import {
  FormInput,
  FormInputElementDefaultProps,
  WeakIdenticationValidationMiscInformationStep,
  WeakIdenticationValidationMiscInformationStepType,
} from '../../WeakIdentication.schema'

// Typing of checkboxes for policies to iterations
type PolicyCheckboxNames =
  | 'newsLetters'
  | 'invitations'
  | 'questionaries'
  | 'marketOpportunities'
  | 'marketApproval'
  | 'marketDisapproval'

type MiscInformationCheckbox = {
  name: PolicyCheckboxNames
  translationKey: string
}

// #region For checkbox iterations
const policyCheckboxes: MiscInformationCheckbox[] = [
  {
    name: 'newsLetters',
    translationKey: 'weak-identification.misc-information-form-email-policy-newsletters',
  },
  {
    name: 'invitations',
    translationKey: 'weak-identification.misc-information-form-email-policy-invitations',
  },
  {
    name: 'questionaries',
    translationKey: 'weak-identification.misc-information-form-email-policy-questionares',
  },
  {
    name: 'marketOpportunities',
    translationKey: 'weak-identification.misc-information-form-email-policy-market-opportunities',
  },
]

const marketingCheckboxes: MiscInformationCheckbox[] = [
  {
    name: 'marketApproval',
    translationKey: 'weak-identification.misc-information-form-privacy-policy-share-information',
  },
  {
    name: 'marketDisapproval',
    translationKey:
      'weak-identification.misc-information-form-provacy-policy-dont-share-information',
  },
]

// #endregion for checkbox iterations

// #region for styled-components
const CheckboxWrapper = styled.div<{ intendent: boolean }>`
  padding-top: ${Spacing.Medium};
  & > label {
    padding-top: ${Spacing.Small};
    padding-bottom: ${Spacing.Small};
    padding-left: ${props => (props.intendent ? Spacing.Large : '0px')};
    color: ${ColorEnum.bfBlue};
  }
`

const MiscInformationFormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Spacing.Large};
`

// #endregion for styled-components

/**
 * WeakIdentication MiscInformationForm step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const MiscInformationForm: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { onSuccess, t } = props
  const navigate = useNavigate()
  const submitRequest = useMutation({
    mutationKey: [WeakIdenticationRequestKeys.miscInformation],
    mutationFn: handleWeakIdenticationMiscInformation,
  })

  /**
   * We send user data to the API and if this request is successful we navigate the user to the next step.
   * @param data
   */
  const onSubmit = (data: WeakIdenticationValidationMiscInformationStepType) => {
    submitRequest.mutate(data, {
      onSuccess: () => {
        return onSuccess(FormInput.miscInformation)
      },
      onError: error => {
        console.warn(error)
      },
    })
  }

  /**
   * Cancels the registration and navigates user back to the main page.
   */
  const handleRouteToMainPage = () => {
    navigate('/')
  }

  return (
    <FormContainer
      validator={withZodValidation(WeakIdenticationValidationMiscInformationStep)}
      onSubmit={onSubmit}
    >
      <Heading level={'h4'} color={'bf-blue'}>
        {t('weak-identification.misc-information-form-title')}
      </Heading>
      <Paragraph>{t('weak-identification.misc-information-form-description')}</Paragraph>
      <Spacer size={'large'} />
      <FormContainer.Field
        name={'firstName'}
        type={'text'}
        label={t('weak-identification.misc-information-form-first-name')}
      />
      <FormContainer.Field
        name={'lastName'}
        type={'text'}
        label={t('weak-identification.misc-information-form-last-name')}
      />
      <Spacer size={'large'} />
      <>
        <Heading level={'h6'} color={'bf-blue'}>
          {t('weak-identification.misc-information-form-policy-title')}
        </Heading>
        <Paragraph>{t('weak-identification.misc-information-form-policy-description')}</Paragraph>
        <Heading level={'h6'} color={'bf-blue'}>
          {t('weak-identification.misc-information-form-privacy-policy-title')}
        </Heading>
        <Paragraph>
          {t('weak-identification.misc-information-form-privacy-policy-description-row-one')}
        </Paragraph>
        <Paragraph>
          {t('weak-identification.misc-information-form-privacy-policy-description-row-two')}
        </Paragraph>
        <Spacer size={'large'} />
        <Heading level={'h6'} color={'bf-blue'}>
          {t('weak-identification.misc-information-form-email-policy-title')}
        </Heading>
        <CheckboxWrapper intendent>
          {policyCheckboxes.map(checkbox => {
            return (
              <Checkbox
                key={checkbox.name}
                name={checkbox.name}
                label={t(checkbox.translationKey)}
              />
            )
          })}
        </CheckboxWrapper>
        <CheckboxWrapper intendent={false}>
          {marketingCheckboxes.map(checkbox => {
            return (
              <Checkbox
                key={checkbox.name}
                name={checkbox.name}
                label={t(checkbox.translationKey)}
              />
            )
          })}
        </CheckboxWrapper>
        <MiscInformationFormFooter>
          <RoundedButton
            onClick={() => handleRouteToMainPage()}
            variant={RoundedButtonVariants.secondary}
            type={'button'}
          >
            {t('weak-identification.misc-information-form-cancel')}
          </RoundedButton>
          <RoundedButton type={'submit'} variant={RoundedButtonVariants.primary}>
            {t('weak-identification.misc-information-form-submit')}
          </RoundedButton>
        </MiscInformationFormFooter>
      </>
    </FormContainer>
  )
}

export { MiscInformationForm }
