import { ValidationErrors } from 'final-form'
import { z } from 'zod'

/**
 * Mutates zodErrors to ValidationErrors
 * @param err
 * @returns
 */
function flattenZodErrors(zodErrors: z.ZodError): ValidationErrors {
  return zodErrors.errors.reduce(
    (prev, e) => ({
      ...prev,
      [e.path.join('.')]: e.message,
    }),
    {} as ValidationErrors
  )
}

/**
 * Validates zodSchema and mutates zodErrors to ValidationErrors
 * @param schema
 * @returns {ValidationErrors}
 */
export const withZodValidation =
  (schema: z.Schema) =>
  (values: Record<string, unknown>): ValidationErrors => {
    const res = schema.safeParse(values)

    if (!res.success) {
      return flattenZodErrors(res.error)
    }

    return {}
  }
