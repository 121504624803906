import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  WeakIdenticationRequestKeys,
  handleWeakIdenticationAuthorizationLink,
} from '../../WeakIdentication.requests'
import {
  FormInputElementDefaultProps,
  WeakIdenticationModes,
  searchQueryParameters,
} from '../../WeakIdentication.schema'
import { setWeakIdenticationRedirectUrl } from '../../WeakIdentication.utils'

/**
 * WeakIdentication e-mail step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const EmailForm: FunctionComponent<FormInputElementDefaultProps> = () => {
  // We need to use useTranslation hook for this occasion
  const { i18n } = useTranslation()
  // We set redirect url to the session storage to be able to redirect user back to the correct page
  const searchParams = new URLSearchParams(window.location.search)
  // This searchQuery parameter is assigned on the parent component. It's also validated everytime searchQuery changes
  // and if it is missing we redirect user to the first step.
  const mode = searchParams.get(searchQueryParameters.mode) as WeakIdenticationModes
  // Reference for iframe to make it work more dynamically
  const iframeReference = useRef<HTMLIFrameElement>(null)

  const authorizationRequest = useQuery(
    [WeakIdenticationRequestKeys.authenticationStep],
    () => handleWeakIdenticationAuthorizationLink(mode),
    { retry: false }
  )

  // UseEffect in here is used to update the iframe src attribute with the authorizationRequest data.
  // We hook it to use isLoading due we don't want this to be rendered multiple times even if the state
  // changes.
  useEffect(() => {
    if (authorizationRequest.isLoading) {
      return
    }

    // We set the iframes src attribute to the authorizationRequest data and add the locale to the query
    if (authorizationRequest.data && iframeReference.current) {
      const src = `${authorizationRequest.data}?locale=${i18n.language}`
      iframeReference.current.src = src
    }
  }, [authorizationRequest.isLoading, i18n.language, authorizationRequest.data])

  // We set the redirect url to the session storage to be able to redirect user back to the correct page
  useEffect(() => {
    setWeakIdenticationRedirectUrl(mode)
  }, [mode])

  // This shouln't happen ever due to the validation on the parent component but if it manages to get here
  // we're gonna return null and not render anything. This might cause some problems if this path is used
  // as a IFrame or a standalone component but in this case it's not. But if this causes the problems
  // just refactor properties to have this property injected from the parent component.
  if (!mode) {
    return null
  }

  // Add loading indicator in here
  if (authorizationRequest.isLoading) {
    return null
  }

  return (
    <iframe
      sandbox={
        'allow-forms allow-same-origin allow-top-navigation allow-scripts allow-popups allow-popups-to-escape-sandbox'
      }
      referrerPolicy="origin"
      width={'100%'}
      name={'Weak authentication email form'}
      ref={iframeReference}
      style={{ overflow: 'hidden', border: 'none', minHeight: 900 }}
    />
  )
}

export { EmailForm }
