/**
 * All the validations that are correspondent for WeakIdentication workflow are
 * stored in here. Here we also export typings by using zod infer to be used on the
 * frontend side. In the future it would be a good idea to use monorepo to store these
 * kind of validations to be used everywhere in this application.
 */
import { TFunction } from 'i18next'
import { FunctionComponent } from 'react'
import { z } from 'zod'

import { isValidName, validateFinnishBusinessID } from '../../../utils/validation-utils'

// #region Enums
export enum SessionStorageKeys {
  email = 'bf_session_email',
  redirectUrl = 'bf_session_redirect_url',
}

export enum searchQueryParameters {
  view = 'view',
  mode = 'mode',
}

export enum WeakIdenticationModes {
  registered = 'registered',
  unRegistered = 'unregistered',
}

export enum FormInput {
  email = 'email',
  miscInformation = 'miscInformation',
  joinToOrganization = 'joinToOrganization',
  success = 'success',
}
// #endregion

//#region Zod validations
// This validation is for searchQuery of weakIdentication wrapper
export const WeakIdenticationSearchQuery = z.object({
  view: z.nativeEnum(FormInput),
  mode: z.nativeEnum(WeakIdenticationModes),
})

// Weak e-mail validation. Basically just checks if the e-mail includes @ and .
export const WeakIdenticationValidationEmailStep = z.object({
  email: z.string().email(),
  mode: z.nativeEnum(WeakIdenticationModes),
})

export const WeakIdenticationValidationVerificationCodeStep = z.object({
  verificationCode: z.string(),
})

export const WeakIdenticationValidationMiscInformationStep = z.object({
  firstName: z
    .string()
    .min(2)
    .max(128)
    .refine(name => {
      return isValidName(name)
    }),
  lastName: z
    .string()
    .min(2)
    .refine(name => {
      return isValidName(name)
    }),
  // Checkboxes
  newsLetters: z.boolean().optional(),
  invitations: z.boolean().optional(),
  questionaries: z.boolean().optional(),
  marketOpportunities: z.boolean().optional(),
  marketApproval: z.boolean().optional(),
  marketDisapproval: z.boolean().optional(),
})

export const WeakIdenticationValidationJoinToOrganizationStep = z.object({
  businessId: z.string().refine(businessId => {
    return validateFinnishBusinessID(businessId)
  }),
})

// #endregion Zod validations

// #region Misc types

// Form configuration works like we have an query parameter called "view" which has a value of formInput.
// By using FormInput we're gonna render a FormInputs element
export type FormInputElementDefaultProps = {
  onSuccess: (currentFormInputKey: FormInput) => void
  t: TFunction
  onModeChange?: (mode: WeakIdenticationModes) => void
}

export type FormInputConfiguration = {
  element: FunctionComponent<FormInputElementDefaultProps>
  indexNumber: number
  translationKey: string
}

export type FormConfiguration = Record<FormInput, FormInputConfiguration>

// #endregion

// #region for Exporting types
export type WeakIdenticationSearchQueryType = z.infer<typeof WeakIdenticationSearchQuery>
export type WeakIdenticationEmailStepType = z.infer<typeof WeakIdenticationValidationEmailStep>
export type WeakIdenticationValidationVerificationCodeStepType = z.infer<
  typeof WeakIdenticationValidationVerificationCodeStep
>
export type WeakIdenticationValidationMiscInformationStepType = z.infer<
  typeof WeakIdenticationValidationMiscInformationStep
>
export type WeakIdenticationJoinOrganizationStepType = z.infer<
  typeof WeakIdenticationValidationJoinToOrganizationStep
>
// #endregion
