import { useMutation } from '@tanstack/react-query'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Heading from '../../../../../components/Heading'
import InformationContainer from '../../../../../components/InformationContainer/InformationContainer.component'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import Spacer from '../../../../../components/Spacer'
import FormContainer from '../../../../../containers/FormContainer'
import { withZodValidation } from '../../../../../containers/FormContainer/FormContainer.utils'
import { Spacing } from '../../../../../shared/enums'
import {
  WeakIdenticationRequestKeys,
  handleWeakIdenticationCheckOrganization,
  handleWeakIdenticationJoinOrganization,
} from '../../WeakIdentication.requests'
import {
  FormInput,
  FormInputElementDefaultProps,
  WeakIdenticationJoinOrganizationStepType,
  WeakIdenticationValidationJoinToOrganizationStep,
} from '../../WeakIdentication.schema'

const JoinOrganizationFormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Spacing.ExtraLarge};
`

/**
 * JoinnToOrganization step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const JoinToOrganizationForm: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { onSuccess, t } = props
  const displayInformationContainer = false
  const submitRequest = useMutation({
    mutationKey: [WeakIdenticationRequestKeys.joinToOrganization],
    mutationFn: handleWeakIdenticationJoinOrganization,
  })
  const checkBusinessId = useMutation({
    mutationKey: [WeakIdenticationRequestKeys.checkOrganization],
    mutationFn: handleWeakIdenticationCheckOrganization,
  })

  /**
   * This function sends a body with businessId to the API and if it's successful it will navigate the user to the next step.
   * @param data
   */
  const onSubmit = (data: WeakIdenticationJoinOrganizationStepType) => {
    submitRequest.mutate(data, {
      onSuccess: () => {
        return onSuccess(FormInput.joinToOrganization)
      },
      onError: error => {
        console.warn(error)
      },
    })
  }

  /**
   * Skips the form submit and navigates the user to the next step.
   */
  const handleSkip = () => {
    onSuccess(FormInput.joinToOrganization)
  }

  const handleBusinessIdCheck = (e: unknown) => {
    const event = e as React.FocusEvent<HTMLInputElement>
    const isValidBusinessId = WeakIdenticationValidationJoinToOrganizationStep.safeParse({
      businessId: event.target.value,
    })

    if (!isValidBusinessId.success) {
      // Set errors
      return
    }

    checkBusinessId.mutate({ businessId: event.target.value })
  }

  return (
    <FormContainer
      validator={withZodValidation(WeakIdenticationValidationJoinToOrganizationStep)}
      onSubmit={onSubmit}
    >
      <Heading color={'bf-blue'} level={'h4'}>
        {t('weak-identification.join-to-organization-form-title')}
      </Heading>
      <Paragraph>
        {t('weak-identification.join-to-organization-form-description-row-one')}
      </Paragraph>
      <Paragraph>
        {t('weak-identification.join-to-organization-form-description-row-two')}
      </Paragraph>
      {displayInformationContainer ? (
        <InformationContainer
          title={t('weak-identification.join-to-organization-form-existing-organization-box-title')}
        >
          <Paragraph>
            {t(
              'weak-identification.join-to-organization-form-existing-organization-box-description-row-one'
            )}
          </Paragraph>
          <Paragraph>
            {t(
              'weak-identification.join-to-organization-form-existing-organization-box-description-row-two'
            )}
          </Paragraph>
          <Paragraph>
            {t(
              'weak-identification.join-to-organization-form-existing-organization-box-description-row-three'
            )}
          </Paragraph>
        </InformationContainer>
      ) : null}
      <Spacer size={'large'} />
      <FormContainer.Field
        onBlur={(_name: string, e: unknown) => handleBusinessIdCheck(e)}
        name={'businessId'}
        type={'text'}
        label={t('weak-identification.join-to-organization-business-id-label')}
      />
      <JoinOrganizationFormFooter>
        <RoundedButton
          variant={RoundedButtonVariants.secondary}
          type={'button'}
          onClick={() => handleSkip()}
        >
          {t('weak-identification.join-to-organization-form-cancel')}
        </RoundedButton>
        <RoundedButton
          disabled={checkBusinessId.isLoading || submitRequest.isLoading || checkBusinessId.isError}
          variant={RoundedButtonVariants.primary}
          type={'submit'}
        >
          {t('weak-identification.join-to-organization-form-submit')}
        </RoundedButton>
      </JoinOrganizationFormFooter>
    </FormContainer>
  )
}

export { JoinToOrganizationForm }
